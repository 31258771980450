@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-family-sans-serif: 'Poppins', sans-serif;

@import "bootstrap/scss/bootstrap";

.grecaptcha-badge {
  visibility: hidden !important;
}

.min-dvh-100 {
  min-height: 100dvh;
}

.gamedraws-no-js {
  .gamedraws-hide-no-js {
    display: none;
  }
}

.gamedraws-svg {
  &--invert {
    filter: invert(1);
  }
}

.gamedraws-header {
  min-height: 115px;

  &__nav {
    font-size: $font-size-base * 1.1;
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    a {
      color: $gray-500;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}

.gamedraws-copyable-text {
  cursor: pointer;
}